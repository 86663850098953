// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueExtended, TextValueItem } from "../../../models/TextValueItem";
import { AnagraficaAderente } from "@/models/anafrafica/AnagraficaAderente";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.ADERENTI;
@Component({})
export default class anagraficaAderentiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.DISTRIBUTORI];
	}
	codiciPagamento: any[] = [];
	province: TextValueExtended[] = [];
	regioni: TextValueExtended[] = [];
	lingue: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	editItem: AnagraficaAderente = new AnagraficaAderente();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = true;


	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 75, filterable: true, filterType: "numeric-int", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				hidden: true,
				editHide: true,
				export: false
			},
			{
				field: "nome",
				title: this.$i18n.t("generico.nome").toString(),
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
			{
				field: "cognome",
				title: this.$i18n.t("generico.cognome").toString(),
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
			{
				field: "dataNascita",
				title: "Data di Nascita",
				type: "date",
				width: 120,
				editable: true,
				format: "{0:dd/MM/yyyy}",
				cell: "defaultCellTemplate",
				headerType: "daterange",
				headerCell: "myHeaderCellTemplate"
			},
			/*{
				field: "distributorePadreID",
				title: this.$i18n.t("generico.padre").toString(),
				width: 100,
				groupable: true,
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				sortBy: "distributorePadre",
				filterable: true,
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
				editSourceText: "text",
				editSourceValue: "value"
			},*/
			{ field: "email", title: this.$i18n.t("generico.email").toString(), width: 150, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			{ field: "codiceFiscale", title: this.$i18n.t("generico.codiceFiscale").toString(), width: 100, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "telefono", export: true, title: this.$i18n.t("generico.telefono").toString(), width: 120, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },

			//{ field: "partitaIva", title: this.$i18n.t("generico.partitaIva").toString(), export: true, width: 100, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "indirizzo", title: this.$i18n.t("generico.indirizzo").toString(), hidden: false, width: 150, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			//{ field: "localita", title: this.$i18n.t("generico.localita").toString(), width: 140, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			{ field: "citta", title: this.$i18n.t("generico.citta").toString(), width: 140, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			/*{
				field: "codiceRegione",
				fieldSourceText: "text",
				fieldSourceValue: "code",
				title: this.$i18n.t("generico.regione").toString(),
				width: 80,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.regioni,
				sortBy: "regione",
				filterable: true,
				filterSourceText: "text",
				filterSourceValue: "code",
				itemsForFilter: this.regioni,
				filterType: "select",
				editSourceText: "text",
				editSourceValue: "code",
				editHide: true
			},*/
			{
				field: "codiceProvincia",
				title: this.$i18n.t("generico.provincia").toString(),
				width: 80,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.province,
				sortBy: "provincia",
				filterable: true,
				itemsForFilter: this.province,
				filterType: "select",
				editType: "select",
				editSource: this.province,
				editSourceText: "text",
				editSourceValue: "code",
				hidden: true
			},

			{ field: "provincia", title: this.$i18n.t("generico.provincia").toString(), width: 120, hidden: false, export: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "cap", title: this.$i18n.t("generico.cap").toString(), width: 100, hidden: false, headerCell: "myHeaderCellTemplate" },

			/*{
				field: "linguaID",
				title: "Lingua",
				width: 100,
				groupable: true,
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.lingue,
				sortBy: "lingua",
				filterable: true,
				itemsForFilter: this.lingue,
				filterType: "select",
				editType: "select",
				editSource: this.lingue,
				editSourceText: "text",
				editSourceValue: "value"
			},*/

			{
				field: "consensoMarketing",
				title: this.$i18n.t("generico.consensoMarketing").toString(),
				width: 120,
				disabled: true,
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterType: "boolean",
				export: true
			},

			{
				field: "note",
				title: this.$i18n.t("generico.note").toString(),
				width: 100,
				hidden: true,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editType: "textarea",
				export: true
			},
			
			{
				field: "cancellato",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 100,
				disabled: true,
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterType: "boolean",
				export: true
			},
			{
				field: "istanteInserimento",
				title: "Istante Inserimento",
				type: "datetime",
				editable: false,
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editHide: this.isNewItem
			},
			{
				field: "istanteModifica",
				title: this.$i18n.t("generico.istanteModifica").toString(),
				type: "datetime",
				editable: false,
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editHide: this.isNewItem
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
			// { field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	sort: any = [{ field: "itemID", dir: "desc" }];

	created() {}

	mounted() {
		var self = this;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => {
				self.province = res as TextValueExtended[];

			}),
			api.getDatiDropDown(api.endpoint.DROPDOWN.REGIONI).then(res => {
				self.regioni = res as TextValueExtended[];

			}),
			api.getDatiDropDown(api.endpoint.DROPDOWN.LINGUE).then(res => (self.lingue = res))
		])
			.then(responses => {
				if (self.showOnlyActive) {
					self.filter.filters.push({
						field: "cancellato",
						operator: "eq",
						value: "!!false!!"
					});
				}

				self.setDefaultPageSize();
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	addNewRow() {
		this.onEdit(new AnagraficaAderente());
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<strong>${item.partitaIva}</strong><h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new AnagraficaAderente(), item);
		self.editSubTitle = item.ragioneSociale;
		//self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		
		debugger
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.aderente") }).toString();
		self.setGridRow<AnagraficaAderente>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false
		});


	}

}
