export class AnagraficaAderente {
	constructor() {
		this.itemID = null;
		this.ragioneSociale = null;
		this.nome = null;
		this.cognome = null;
		this.dataNascita = null;
		this.email = null;
		this.telefono = null;
		this.distributorePadreID = null;
		this.indirizzo = null;
		this.localita = null;
		this.citta = null;
		this.codiceProvincia = null;
		this.cap = null;
		this.partitaIva = null;
		this.linguaID = null;
		this.cancellato = null;
		this.codiceFiscale = null;
		this.note = null;
		this.istanteInserimento = null;
		this.istanteModifica = null;
		this.provincia = null;
		this.regione = null;
		this.consensoMarketing = null;
	}
	itemID: number;
	ragioneSociale = null;
	nome = null;
	cognome = null;
	dataNascita: Date = null;
	email = null;
	telefono = null;
	distributorePadreID = null;
	indirizzo = null;
	localita = null;
	citta = null;
	codiceProvincia = null;
	cap = null;
	partitaIva = null;
	linguaID = null;
	cancellato = null;
	codiceFiscale = null;
	note = null;
	istanteInserimento = null;
	istanteModifica = null;
	provincia = null;
	regione = null;
	consensoMarketing = null;
}